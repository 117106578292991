/*globals $*/
'use strict';

import lozad from 'lozad'
const getSvg = () => import("./external/functions/svg");
const getHeaderFunctions = () => import("./external/functions/header");
const getSuffle = () => import("./external/functions/shuffle");
import Dropzone from "dropzone";

/* DETECT MOBILE */
var isMobile = false;
if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
    || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) {
    isMobile = true;
}

Dropzone.autoDiscover = false;

$( document ).ready(function() {
    if($(".svg").length > 0) {
        getSvg().then(svgModule => {
            svgModule.prepareSvg();
        });
    }

    $('#changeLang').on('click', function (){
        var value = $(this).attr('data-href');
        $('#modal_edit_exit').attr('href', value);
    });


    $('#removeImg').on('click', function(){
        var src =  $('#image_holder').val();
        $('#thumbnail').attr('src', src);
        $('#image_featured').attr('value', src);
    });

    $('#removeSeoImg').on('click', function(){
        var src =  $('#image_holder').val();
        $('#seo_thumbnail').attr('src', src);
        $('#og_image').attr('value', src);
    });

    $('[id^="deleteOtherAddress_"]').on('click', function (){
        $('#address-item-' + $(this).attr('data-id')).remove();
        var value = $('#count_other_address').val();
        $('#count_other_address').val( value - 1);
    });

    $('#other-user_section').css('display', 'none');

    $('#addOtherUser').on('click', function (){
        $('#other-user_section').css('display', 'block');
    })

    $('#checkUserGroup').on('click', function(){
        if ($("[name='user-user_name[]']").length > 0){
            var url = $('#update_user_group-form').data('action');
            var formData = $('#update_user_group-form').serialize();
            var go_url = $('#go-url').val();

            $.ajax({
                type: "POST",
                url: url,
                data: formData,
                async: false,
                success: function(data) {
                    data = JSON.parse(data);

                    if(data) {
                        if(typeof data !== 'object' && data !== 0) {
                            window.location.href = go_url.slice(0, -1) + data;
                        }else{
                            $('#success_message').removeClass('message-hidden');
                        }
                    }else {
                        $('#error_message').removeClass('message-hidden');
                    }

                },
            });

            return false;
        }
    })

    $('#users').on('change', function(){
        var email = $(this).find('option:selected').text();
        var name = $(this).val();

        var count = $("[id^='user-item-']").length;
        count = count + 1;
        $('#user-list').append(
            '<li class="form_group list-item" id="user-item-' + count +'">' +
            '<div class="flex-div">' +
            '<a id="user_delete-' + count + '" data-id="' + count + '" class="item-delete text-color" data-abc="true"><img src="/images/delete.png"></a>' +
            '<div class="list-item-bold">' + email +
            '</div>' +

            '<input type="hidden" name="user-user_name[]" value="' + name +'">' +
            '<input type="hidden" name="user-user_email[]" value="' + email +'">' +
            '</li>'
        );
        $('#name').val('');
        $('#email').val('');
    })

    $('[id^="user_delete-"]').on('click', function (){
        $('#user-item-' + $(this).attr('data-id')).remove();
        var value = $('#count_other_user').val();
        $('#count_other_user').val( value - 1);
    });

    $('#addOtherFile').on('click', function(){
        var count = $("[id^='label_file_']").length;
        count = count + 1;

        $('#multiple_files').append(
            '<hr>' +
            '<label>Document adjunt</label>' +
            '<div class="custom-file mb-4">'+
                '<input type="file" name="file_' + count + '" id="file_' + count + '" data-count="'+ count +'" accept="application/pdf,.doc,.docx,.xls,.xlsx,.csv">' +
                '<label id="label_file_' + count + '" class="custom-file-label" for="file_' + count + '"></label>'+
            '</div>'+
            '<div class="mb-3">' +
            '<label>Descripció arxiu</label>' +
            '<textarea rows="2" type="text" class="form-control" id="description_' + count + '" name="description[]"></textarea>' +
            '</div>'
        );
    });


    $('body').on('change', '[id^="file_"]', function(e){
        var count = $(this).attr('data-count');

        var fileName = e.target.files[0].name;
        $('#label_file_' + count).html(fileName);
    });


    $('.openMedia').on('click', function(){
        $('#media-info').css('display', 'block');
    });

    $('.openSeoMedia').on('click', function(){
        $('#seo-media-info').css('display', 'block');
    });

    $('.openFileMedia').on('click', function(){
        $('#file-media-info').css('display', 'block');
    });

    $('#media-section-close').on('click', function() {
        $('#media-info').css('display', 'none');
    });

    $('#file-media-section-close').on('click', function() {
        $('#file-media-info').css('display', 'none');
    });

    $('#btn_media-section-close').on('click', function() {
        $('#media-info').css('display', 'none');
    });

    $('#seo-media-section-close').on('click', function() {
        $('#seo-media-info').css('display', 'none');
    });

    $('#btn_seo-media-section-close').on('click', function() {
        $('#seo-media-info').css('display', 'none');
    });

    $('#file_media-section-close').on('click', function() {
        $('#file-media-info').css('display', 'none');
    });
    if($('#open_newsletter').length){
        document.querySelector('[data-target="#modal_newsletter"]').click();
    }

    $('[id^="img-"]').on('click', function(){
        $('[id^="img-"]').removeClass('img-active');
        $(this).addClass('img-active');
        $('#featured_image').val($(this).attr('data-url')).change();
        $('#image_featured').val($(this).attr('data-url')).change();
        $('#thumbnail').attr('src', $(this).attr('data-url'));

    });

    $('[id^="seo-img-"]').on('click', function(){
        $('[id^="seo-img-"]').removeClass('img-active');
        $(this).addClass('img-active');
        $('#og_image').val($(this).attr('data-url')).change();;
        $('#seo-featured_image').val($(this).attr('data-url')).change();;
        $('#seo_thumbnail').attr('src', $(this).attr('data-url'));

    });

    $('#selectFromGallery').on('click', function(){
        if ($('#featured_image').val() !== '0'){
            $('#media-info').css('display', 'none');
        }
    });

    $('#selectSeoFromGallery').on('click', function(){
        if ($('#seo-featured_image').val() !== '0'){
            $('#seo-media-info').css('display', 'none');
        }
    });

    $('#seo-uploadImage').on('click', function(){
        $('#seo-media-info').css('display', 'none');
    });

    $('#uploadImage').on('click', function(){
        $('#media-info').css('display', 'none');
    });

    $('input[name=commission]').on('change', function () {
        var value = $('input[name=commission]:checked').val();

        if(value == 1){
            $('#comission_type').removeClass('hidden');
        }else{
            $('#comission_type').addClass('hidden');
        }
    });

    if($('input[name=commission]')){
        var value = $('input[name=commission]:checked').val();

        if(value == 1){
            $('#comission_type').removeClass('hidden');
        }else{
            $('#comission_type').addClass('hidden');
        }
    };

    if($('[id^="delete_content-"]')){
        if ($('[id^="delete_content-"]').on('click', function(){
            var url = $(this).attr('data-url');
            $('#content_delete_link').attr("href", url);
        }));
    }

    $('#saveOrganization').on('click', function(){
        $('#update_org-form').submit();
    });

    $('#generate_password').on('click', function(){
        $('#password').val(randString(12));
    });

    $('input[name=category]').on('change', function(){
        var value = $('input[name=category]:checked').val();

        if($('#image_featured').length > 0) {
            var img = $('#image_featured').val();

            if (img.includes('image-holder')) {
                switch (value) {
                    case '0':
                        $("#thumbnail").attr("src", "http://hundreds-wordpress-uploads.s3.eu-west-3.amazonaws.com/ccoc/uploads/2021/08/image-holder.jpg");
                        $('#image_featured').val("http://hundreds-wordpress-uploads.s3.eu-west-3.amazonaws.com/ccoc/uploads/2021/08/image-holder.jpg");
                        break;
                    case '5':
                        $("#thumbnail").attr("src", "http://hundreds-wordpress-uploads.s3.eu-west-3.amazonaws.com/ccoc/uploads/2021/08/image-holder_cambra.png");
                        $('#image_featured').val("http://hundreds-wordpress-uploads.s3.eu-west-3.amazonaws.com/ccoc/uploads/2021/08/image-holder_cambra.png");
                        break;
                    case '6':
                        $("#thumbnail").attr("src", "http://hundreds-wordpress-uploads.s3.eu-west-3.amazonaws.com/ccoc/uploads/2021/08/image-holder.jpg");
                        $('#image_featured').val("http://hundreds-wordpress-uploads.s3.eu-west-3.amazonaws.com/ccoc/uploads/2021/08/image-holder.jpg");
                        break;
                    case '7':
                        $("#thumbnail").attr("src", "http://hundreds-wordpress-uploads.s3.eu-west-3.amazonaws.com/ccoc/uploads/2021/08/image-holder_projects.png");
                        $('#image_featured').val("http://hundreds-wordpress-uploads.s3.eu-west-3.amazonaws.com/ccoc/uploads/2021/08/image-holder_projects.png");
                        break;
                    case '8':
                        $("#thumbnail").attr("src", "http://hundreds-wordpress-uploads.s3.eu-west-3.amazonaws.com/ccoc/uploads/2021/08/image-holder_others.png");
                        $('#image_featured').val("http://hundreds-wordpress-uploads.s3.eu-west-3.amazonaws.com/ccoc/uploads/2021/08/image-holder_others.png");
                        break;
                    default:
                        $("#thumbnail").attr("src", "http://hundreds-wordpress-uploads.s3.eu-west-3.amazonaws.com/ccoc/uploads/2021/08/image-holder.jpg");
                        $('#image_featured').val("http://hundreds-wordpress-uploads.s3.eu-west-3.amazonaws.com/ccoc/uploads/2021/08/image-holder.jpg");
                        break;
                }
            }
        }
    });

    $('#delete_file').on('click', function(){
        $('#input_file').addClass('hidden');
        $('#select_file').removeClass('hidden');
        $('#file').val('');
    });

    if($('#organization').length) {
        var org = new Dropzone('#organization', {
            acceptedFiles: ".csv, .xls, .xlsx",
            paramName: 'file',
            maxFiles: 1,
            uploadMultiple: false,
            processing: function (file, response) {
                $('#excel-loader_org').css('display', 'block');
            },
            success: function (file, response) {
                $('#excel-loader_org').css('display', 'none');
                if (response == 'true' || response == '"true"') {
                    $('#success_message').css('display', 'block');
                } else {
                    $('#error_message').css('display', 'block');
                }
            },
            error: function (file, error, xhr) {
                $('#excel-loader_org').css('display', 'none');
                $('#error_message').css('display', 'block');
            }
        });
    }

    if($('#seoImageUpload').length) {
        var seoImg = new Dropzone('#seoImageUpload', {
            acceptedFiles: 'image/jpeg,image/png',
            paramName: 'upload_file',
            maxFiles: 1,
            uploadMultiple: false,
            processing: function (file, response) {
                $('#excel-loader').css('display', 'block');
            },
            success: function (file, response) {
                $('#excel-loader').css('display', 'none');
                $('#og_image').val(JSON.parse(response));
                $('#seo_thumbnail').attr('src', response);
                $('#seo-media-info').css('display', 'none');
            },
            error: function (file, error, xhr) {
                $('#excel-loader').css('display', 'none');
                $('#seo-error-upload').css('display', 'block');
            }
        });
    }

    if($('#uploadMedia').length) {
        var img = new Dropzone('#uploadMedia', {
            acceptedFiles: 'image/jpeg,image/png',
            paramName: 'upload_file',
            maxFiles: 1,
            uploadMultiple: false,
            processing: function (file, response) {
                $('#excel-loader').css('display', 'block');
            },
            success: function (file, response) {
                $('#excel-loader').css('display', 'none');
                $('#image_featured').val(JSON.parse(response));
                $('#thumbnail').attr('src', JSON.parse(response));
                $('#media-info').css('display', 'none');
            },
            error: function (file, error, xhr) {
                $('#excel-loader').css('display', 'none');
                $('#error-upload').css('display', 'block');
            }
        });
    }

    if($('#uploadFileMedia').length) {
        var img = new Dropzone('#uploadFileMedia', {
            acceptedFiles: 'application/pdf,.doc,.docx,.xls,.xlsx,.csv',
            paramName: 'upload_file',
            maxFiles: 1,
            uploadMultiple: false,
            processing: function (file, response) {
                $('#excel-loader').css('display', 'block');
            },
            success: function (file, response) {
                $('#excel-loader').css('display', 'none');
                $('#file').val(JSON.parse(response));
                $('#upload_file').val(JSON.parse(response));
                $('#accept').css('display', 'block');
                $('#file-media-info').css('display', 'none');
            },
            error: function (file, error, xhr) {
                $('#excel-loader').css('display', 'none');
                $('#error-upload').css('display', 'block');
            }
        });
    }

    $('.post_fields').css('display', 'none');

    $('[name="have_post"]').change(function() {

        if($(this).val() == 1){
            $('.post_fields').css('display', 'block');

        }else{
            $('.post_fields').css('display', 'none');
        }
    });

    $('[name="responsible"]').change(function(){
        if($(this).val() == 1){
            $('[name="visibility"]').prop('disabled', true)
            $('[name="visibility"]').filter('[value=private]').prop('checked', true);
        }else{
            $('[name="visibility"]').prop('disabled', false)
        }
    })

    var timeout = null;

    $('#event_title').keydown(function() {
        clearTimeout(timeout);

        timeout = setTimeout(function() {

            if($('#event_slug').val() == '') {
                var slug = convertToSlug($('#event_title').val());

                $.ajax({
                    type: "GET",
                    url: '/eventos/check/slug/' + slug,
                    async: false,
                    success: function(data) {
                        var resp = JSON.parse(data);

                        if( resp == 'ok'){
                            $('#event_slug').val(slug);
                        }else{
                            $('#event_slug').val(slug + '-' + resp);
                        }
                    },
                });
            }
        }, 1500);
    });

    $('#checkCoefficient').on('click', function (){
        $('#update_coefficient').submit();
    });



    $('[name="section"]').change(function() {
        var value = $('#section').find(":selected").val()
        $("#quota").val(value);
    });

});

function randString(length){
    var chars = "abcdefghijklmnopqrstuvwxyz!@#$%^&*()-+<>ABCDEFGHIJKLMNOP1234567890";
    var pass = "";
    for (var x = 0; x < length; x++) {
        var i = Math.floor(Math.random() * chars.length);
        pass += chars.charAt(i);
    }
    return pass;
}

function convertToSlug(Text)
{
    return Text
        .toLowerCase()
        .replace(/[^\w ]+/g,'')
        .replace(/ +/g,'-')
        ;
}

function removeOptions(selectbox)
{
    var i;
    for(i = selectbox.options.length - 1 ; i >= 0 ; i--)
    {
        if(selectbox[i].value != '0' && selectbox[i].value != ''){
            selectbox.remove(i);
        }
    }
}

function addOption(selectbox, text, value)
{
    selectbox.options[selectbox.options.length] = new Option( text, value );
}
