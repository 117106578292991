/*globals $*/
'use strict';

const getValidationFunctions = () => import("./validate");

$( document ).ready(function() {

    $('[id^="checkPost"]').on('click', function(e){
        $('#status').val($(this).attr('data-type'));

        e.preventDefault();
        getValidationFunctions().then(validateModule => {
            validateModule.validatePostForm();
        });
    });

    $('[id^="checkLegislation"]').on('click', function(e){
        $('#status').val($(this).attr('data-type'));

        e.preventDefault();
        getValidationFunctions().then(validateModule => {
            validateModule.validateLegislationForm();
        });
    });

    $('[id^="checkWarning"]').on('click', function(e){
        e.preventDefault();
        getValidationFunctions().then(validateModule => {
            validateModule.validateWarningForm();
        });
    });

    $('[id^="checkWarningWord"]').on('click', function(e){
        e.preventDefault();
        getValidationFunctions().then(validateModule => {
            validateModule.validateWarningWordForm();
        });
    });

    $('[id^="checkEvent"]').on('click', function(e){
        $('#status').val($(this).attr('data-type'));

        e.preventDefault();
        getValidationFunctions().then(validateModule => {
            validateModule.validateEventForm();
        });
    });

    $('#checkMember').on('click', function(e){
        e.preventDefault();
        getValidationFunctions().then(validateModule => {
            validateModule.validateMemberForm();
        });
    });

    $('#checkAccess').on('click', function(e){
        e.preventDefault();
        getValidationFunctions().then(validateModule => {
            validateModule.validateAccessForm();
        });
    });

    $('[id^="checkPopup"]').on('click', function(e){
        $('#status').val($(this).attr('data-type'));

        e.preventDefault();
        getValidationFunctions().then(validateModule => {
            validateModule.validatePopupForm();
        });
    });

    $('#checkTenders').on('click', function(e){
        e.preventDefault();
        getValidationFunctions().then(validateModule => {
            validateModule.validateTendersForm();
        });
    });

    $('#checkGroup').on('click', function(e){
        e.preventDefault();
        getValidationFunctions().then(validateModule => {
            validateModule.validateGroupForm();
        });
    });

    $('#addBoardDirectors').on('click', function(e){
        e.preventDefault();
        getValidationFunctions().then(validateModule => {
            validateModule.validateBoardForm();
        });
    });

    $('#addCcocTeam').on('click', function(e){
        e.preventDefault();
        getValidationFunctions().then(validateModule => {
            validateModule.validateCcocForm();
        });
    });

    $('#addOtherAddress').on('click', function(e){
        e.preventDefault();
        getValidationFunctions().then(validateModule => {
            validateModule.validateAddressForm();
        });
    });

    $('#checkSeo').on('click', function(e){
        e.preventDefault();
        getValidationFunctions().then(validateModule => {
            validateModule.validateSeoForm();
        });
    });

    $('[id^="checkFile"]').on('click', function(e){

        if($(this).attr('data-type') == 'no_email'){
            $('#email').val(false)
            $('#status').val($('#checkFile').attr('data-type'));
        }else{
            $('#status').val($(this).attr('data-type'));
        }

        e.preventDefault();
        getValidationFunctions().then(validateModule => {
            validateModule.validateFileForm();
        });
    });

    $('[id^="checkProject"]').on('click', function(e){
        $('#status').val($(this).attr('data-type'));

        e.preventDefault();
        getValidationFunctions().then(validateModule => {
            validateModule.validateProjectForm();
        });
    });

    $('#addUser').on('click', function(e){
        e.preventDefault();
        getValidationFunctions().then(validateModule => {
            validateModule.validateOtherUserForm();
        });
    });

    $('#checkDownload').on('click', function(e){
        e.preventDefault();
        $('#hundreds__newsletter_loader').removeClass("hidden");

        getValidationFunctions().then(validateModule => {
            validateModule.validateDownloadForm();
        });
    });
});

